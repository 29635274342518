<template>
  <!-- 新闻动态 -->
  <div class="trends">
    <div
      class="current-nav centerText"
      v-if="Object.keys(dotaiData).length != 0"
      :style="{
        background: `url(${dotaiData.xinwengMsg}) 100% no-repeat`
      }"
    >
      <h1>{{ dotaiData.xinwenMsg }}</h1>
      <h2>{{ dotaiData.xinwenText }}</h2>
    </div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="trends-nr">
      <div class="trends-nr-upper">
        <ul>
          <li
            v-for="(item, idx) in headList"
            :key="idx"
            :class="[active == idx ? 'on' : '']"
            @click="onChangeDiv(item, idx)"
          >
            <a
              :href="'#/zz_trends?currentIdx=' + idx"
              :class="[active == idx ? 'on' : '']"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="trends-nr-content" v-if="currentDiv">
        <ul class="pc_ul">
          <li v-for="(item, idx) in tablList" :key="idx" @click="toLink(item)">
            <a
              :href="
                '#/zz_trends?currentIdx=' + currentIdx + '&detailsId=' + item.id
              "
            >
              <div class="li_nav">
                <div class="li_nav_left">
                  <img :src="item.cover_image" alt="" style="width:100%" />
                </div>
                <div class="li_nav_right">
                  <h1 class="ellipsis">{{ item.title }}</h1>
                  <h2>{{ item.miaoshu }}</h2>
                  <div class="li_nav_dates">
                    <span>{{ item.create_time }}</span>
                    <i class="el-icon-view"></i>{{ item.reads }}
                  </div>
                </div>
              </div>
            </a>
          </li>
          <onLoading :show="loading"></onLoading>
        </ul>
        <ul class="sj_ul">
          <li v-for="(item, idx) in tablList" :key="idx">
            <div class="sj_ul_left">
              <span>{{ item.create_time.slice(8) }}</span>
              {{ item.create_time.slice(0, 7) }}
            </div>
            <div class="sj_ul_right">
              <h1>{{ item.title }}</h1>
              <p>{{ item.miaoshu }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="trends-nr-details" v-else>
        <template v-if="maincontent">
          <div v-html="maincontent"></div>
        </template>
        <template v-else>
          <div class="details_empty">
            <img src="@/assets/image/xwdt/xwdt-data.webp" alt="" />
          </div>
        </template>
      </div>
      <div class="all-pagination" v-if="currentDiv">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="tabPag.page"
          background
          :page-size="6"
          layout="prev, pager, next,total"
          :total="tabPag.total"
          v-if="tabPag.total > 6"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
  </div>
</template>

<script>
import onLoading from '@/common/Loading/onLoading.vue'
import { getXwxqData, getAllXwData, getXwTypeData, getPageMsg } from '@/api/api'
export default {
  components: {
    onLoading
  },
  data () {
    return {
      active: 0,
      dotaiData: {},
      headList: [],
      currentDiv: true,
      currentId: '',
      tablList: [],
      tabPag: {
        page: 1,
        total: 0
      },
      maincontent: '',
      loading: false,
      project_id: '',
      detailsId: '',
      currentIdx: ''
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.detailsId) {
          this.detailsId = this.$route.query.detailsId
        } else {
          this.detailsId = ''
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      if (this.$route.query.detailsId) {
        this.detailsId = this.$route.query.detailsId
        this.toLink({ id: this.detailsId })
        this.getXqData(this.$route.query.detailsId)
      } else {
        this.detailsId = ''
      }
      this.project_id = sessionStorage.getItem(this.changeData() + 'project')
      this.allxwData(this.tabPag.page, '', this.project_id)
      getXwTypeData(1, this.project_id).then(res => {
        if (res.data.code == 1) {
          res.data.data.unshift({
            name: '全部',
            id: ''
          })
          this.headList = res.data.data
        }
      })
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.dotaiData = res.data.data.xinwenMsg
        }
      })
    },
    allxwData (i, x, d) {
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.loading = false
          this.tablList = res.data.data.list
          this.tabPag.total = res.data.data.total
        }
      })
    },
    toLink (e) {
      this.currentDiv = false
      this.getXqData(e.id)
    },
    getXqData (i) {
      getXwxqData(i).then(res => {
        if (res.data.code == 1) {
          this.maincontent = res.data.data.maincontent
        }
      })
    },
    onChangeDiv (e, i) {
      this.currentIdx = i
      this.currentDiv = true
      this.active = i
      this.currentId = e.id
      this.dotaiData.title = e.name
      this.loading = true
      this.allxwData(1, e.id, this.project_id)
    },
    handleCurrentChange (val) {
      this.loading = true
      this.allxwData(val, this.currentId, this.project_id)
    }
  },
  mounted () {
    if (this.$route.query.new_id) {
      this.currentDiv = false
      this.getXqData(this.$route.query.new_id)
    }
    this.getData()
    let idx = this.$route.query.currentIdx
    if (idx) {
      this.active = idx
    }
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.trends {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .trends-nr {
    background-color: #f5f5f5;
    padding-bottom: 50px;
    .trends-nr-upper {
      text-align: center;
      padding: 20px 0;
      background-color: #fff;
      border-bottom: 1px solid #f5f5f5;
      ul {
        display: flex;
        justify-content: center;
        li {
          display: block;
          color: #373737;
          margin: 0 10px;
          cursor: pointer;
          white-space: nowrap;
          a {
            display: block;
            padding: 10px 30px;
            border-radius: 30px;
          }
        }
        li {
          .on,
          a:hover {
            background-color: #e62022;
            color: #fff;
          }
        }
      }
    }
    .trends-nr-content,
    .trends-nr-details {
      width: 86%;
      margin: 0 auto;
      overflow: hidden;
      .pc_ul {
        margin-top: 45px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        li {
          width: 48%;
          overflow: hidden;
          margin-right: 2%;
          background: #fff;
          transition: all 0.5s;
          margin-bottom: 2%;
          cursor: pointer;
          .li_nav {
            padding: 30px;
            display: flex;
            justify-content: space-between;
            .li_nav_left {
              width: 200px;
            }
            .li_nav_right {
              width: calc(100% - 230px);
              display: flex;
              flex-direction: column;
              justify-content: center;
              h1 {
                font-size: 18px;
                font-weight: normal;
              }
              h2 {
                font-size: 14px;
                color: #666666;
                overflow: hidden;
                margin: 10px 0;
                line-height: 1.5;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; //两行，根据需求可更改
                overflow: hidden;
                text-overflow: ellipsis;
                color: #999;
              }
            }
            .li_nav_dates {
              font-size: 14px;
              color: #a3afb7;
              i {
                margin-left: 10px;
                margin-right: 5px;
              }
            }
          }
        }
        li:nth-child(2n + 0) {
          margin-right: 0;
        }
      }
      .sj_ul {
        display: none;
        flex-wrap: wrap;
        li {
          width: calc(100% - 1rem);
          padding: 0.8rem 0.5rem;
          margin-top: 0.8rem;
          overflow: hidden;
          background: #fff;
          transition: all 0.5s;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
          .sj_ul_left {
            width: 20%;
            border-right: 1px solid #f5f5f5;
            text-align: center;
            overflow: hidden;
            font-size: 0.7rem;
            color: #666666;
            line-height: 1rem;
            transition: all 0.3s;
            span {
              display: block;
              color: #333;
              font-size: 1.5rem;
              line-height: 1.3rem;
              height: 1.3rem;
              transition: all 0.3s;
            }
          }
          .sj_ul_right {
            width: 75%;
            h1 {
              font-size: 0.8rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            p {
              margin-top: 0.5rem;
              font-size: 0.6rem;
              color: #999;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .trends-nr-details {
      margin-top: 50px;
      background-color: #fff;
      width: calc(86% - 60px);
      padding: 30px;
      min-height: calc(600px - 60px);
      line-height: 1.5;
      .details_empty {
        width: 100%;
        text-align: center;
        font-size: 30px;
      }
    }
  }
}
</style>
